<template>
  <div
    class="py-4 px-2"
    style="
      display: flex;
      padding: 20px;
      height: 100%;
      overflow-y: auto;
      background: #fff;
    "
  >
    <div style="flex: 1">
      <el-form
        :model="formData"
        :rules="formRules"
        label-width="1.3rem"
        ref="form"
        style="padding-bottom: 130px"
      >
        <el-form-item
          label="民意标题"
          required
          :rules="formRules.name"
          prop="name"
        >
          <el-input v-model="formData.name" />
        </el-form-item>
        <el-form-item label="是否公开" prop="gk_status" clearable required>
          <el-radio v-model="formData.gk_status" :label="1">是</el-radio>
          <el-radio v-model="formData.gk_status" :label="2">否</el-radio>
        </el-form-item>
        <el-form-item label="民意类别" prop="sqmytype_id" clearable required>
          <el-select v-model="formData.sqmytype_id" placeholder="请选择">
            <el-option
              v-for="item in classifyData"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源渠道" prop="sqmychannel_id" clearable required>
          <el-select v-model="formData.sqmychannel_id" placeholder="请选择">
            <el-option
              v-for="(item, index) in tianxs"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="问题分析" required prop="problem_analysis">
          <el-input
            type="textarea"
            :rows="8"
            v-model="formData.problem_analysis"
          />
        </el-form-item>

        <el-form-item label="建议办法" required prop="suggested_approach">
          <el-input
            type="textarea"
            :rows="8"
            v-model="formData.suggested_approach"
          />
        </el-form-item>

        <el-form-item label="上传图片">
          <el-upload
            :action="baseurl + '/api/login/upload'"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="uploadsuccess"
            :file-list="imaglist"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>

        <el-form-item label="上传附件">
          <el-upload
            class="upload-demo"
            :action="baseurl + '/api/login/upload'"
            multiple
            :on-success="uploadsuccessfujian"
            :file-list="fileList"
            :show-file-list="false"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div
            v-for="(item, index) in fileList"
            :key="index"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-right: 20%;
              width: 50%;
            "
          >
            <div
              class="filenamebox"
              @click="goopen(item.url)"
              style="display: flex; align-items: center; cursor: pointer"
            >
              <i
                class="el-icon-circle-check"
                style="
                  color: rgb(127, 204, 89);
                  margin-right: 0.5rem;
                  font-size: 1.2rem;
                "
              >
              </i>
              {{ item.file_name ? item.file_name : item.name }}
            </div>
            <div style="cursor: pointer" @click="removelist(index)">
              <i class="el-icon-close"></i>
            </div>
          </div>
        </el-form-item>

        <div class="mt-16 pl-16" style="padding: 0 20px">
          <el-button type="info" @click="onCancel"> 取消 </el-button>
          <el-button type="primary" @click="onSubmit(2)"> 提交 </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baseurl: "",
      formDatalist: [], //其他提案
      formData: {
        name: "",
        gk_status: 1,
        sqmytype_id: "",
        sqmychannel_id: "",
        problem_analysis: "",
        suggested_approach: "",
        zhaopian: "",
        fujian: "", //附件id
        filelist: [], //附件列表
      },
      formRules: {
        name: [
          {
            required: true,
            message: "请填写民意标题",
            whitespace: true,
          },
        ],
        problem_analysis: [
          {
            required: true,
            message: "请输入案由分析",
            whitespace: true,
          },
        ],
        suggested_approach: [
          {
            required: true,
            message: "请输入建议办法",
            whitespace: true,
          },
        ],
        sqmytype_id: [
          {
            required: true,
            message: "请选择民意类别",
            trigger: "change",
          },
        ],
        sqmychannel_id: [
          {
            required: true,
            message: "请选择来源渠道",
            trigger: "change",
          },
        ],
      },
      classifyData: [], //提案类型
      tianxs: [],
      dialogVisible: false,
      dialogImageUrl: "",
      imaglist: [], //图片上传文件
      gzurl: "", //公章
      gz_id: "", //公章id
      qianziid: "", //签名id
      qianziurl: "", //签名url
      fileList: [], //附件列表
      user_id: "", //联名人
      user_name: "",

      userlist: "", //用户列表
      loading: false,
    };
  },
  created() {
    this.baseurl = this.$URL;
    //获取提案类型
    this.$post({
      url: "/api/sqmytype/index",
      params: {
        p: 1,
        size: 9999,
      },
    }).then((res) => {
      this.classifyData = res.list;
    });
    this.$post({
      url: "/api/sqmychannel/index",
      params: {
        p: 1,
        size: 9999,
      },
    }).then((res) => {
      this.tianxs = res.list;
    });
    if (this.$route.query.id) {
      this.$post({
        url: "/api/sqmy/details",
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        this.formData = res;
        if (res.zhaopian.length > 0) {
          res.zhaopian.forEach((item, index) => {
            let obj = {
              name: item.filename,
              url: item.fullurl,
              id: item.id,
            };
            this.imaglist.push(obj);
          });
        }
        if (res.fujian.length > 0) {
          res.fujian.forEach((item, index) => {
            let obj2 = {
              name: item.filename,
              url: item.fullurl,
              id: item.id,
            };
            this.fileList.push(obj2);
          });
        }
      });
    }
  },
  methods: {
    onSubmit(iii) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.imaglist.length > 0) {
            //照片
            this.formData.zhaopian = this.imaglist.map((item) => {
              return item.id;
            });
            this.formData.zhaopian = this.formData.zhaopian.join(",");
          }
          if (this.fileList.length > 0) {
            //附件
            this.formData.fujian = this.fileList.map((item) => {
              return item.id;
            });
            this.formData.fujian = this.formData.fujian.join(",");
          }

          if (this.$route.query.id) {
            delete this.formData.createtime;
            delete this.formData.deletetime;
          }
          let url = this.$route.query.id ? "/api/sqmy/edit" : "/api/sqmy/add";

          this.$post({
            url,
            params: this.formData,
          }).then((res) => {
            this.$message.success("操作成功");
            this.$router.go(-1);
          });
        } else {
          return false;
        }
      });
    },

    onCancel() {
      this.$router.go(-1);
    },

    goopen(url) {
      window.open(url, "_blank");
    },

    //附件上传相关--------------
    removelist(index) {
      this.fileList.splice(index, 1);
    },
    uploadsuccessfujian(response, file, fileList) {
      this.fileList.push(response);
    },
    //附件上传结束-------------------------

    //图片上传相关-------------
    uploadsuccess(response, file, fileList) {
      console.log(response);
      response.url = response.fullurl;
      this.imaglist.push(response);
    },
    handleRemove(file, fileList) {
      this.imaglist = [];
      fileList.forEach((item, index) => {
        this.imaglist.push(item.response ? item.response.data : item);
      });
      console.log(this.imaglist);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //图片上传结束--------------------

    //公章上传--------------------------------
    handleAvatarSuccess(res) {
      this.gzurl = res.fullurl;
      this.gz_id = res.id;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG或PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },
    //公章上传--结束------------------------------
  },
};
</script>

<style lang="scss" scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #999;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 145px;
  height: 145px;
  line-height: 145px;
  text-align: center;
}

.avatar {
  width: 145px;
  height: 145px;
  display: block;
}

::v-deep .el-icon-circle-check:before {
  font-size: 0.2rem;
}

::v-deep .el-icon-circle-check {
  font-size: 0.2rem !important;
  line-height: 0.16rem;
  height: 0.16rem;
}

::v-deep .el-dialog__header {
  display: flex;
}

.filenamebox:hover {
  color: #409eff;
}
</style>
